import React, { useRef, useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import ImageSlide from "../ImageSlide"
import useOnScreen from "../hooks/useInView"

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      hero: allPrismicHomepage {
        nodes {
          data {
            hero_heading {
              text
            }
            hero_subheading {
              text
            }
            body {
              ... on PrismicHomepageBodyImageGallery {
                id
                items {
                  gallery_image {
                    alt
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  //Data from Prismic
  const heroHeading = data.hero.nodes[0].data.hero_heading.text
  const heroSubHeading = data.hero.nodes[0].data.hero_subheading.text

  // Image source for each gallery
  const imageGallery1 = data.hero.nodes[0].data.body[0].items.map(image => {
    return image.gallery_image.localFile.childImageSharp.fluid
  })
  const imageGallery2 = data.hero.nodes[0].data.body[1].items.map(image => {
    return image.gallery_image.localFile.childImageSharp.fluid
  })
  const imageGallery3 = data.hero.nodes[0].data.body[2].items.map(image => {
    return image.gallery_image.localFile.childImageSharp.fluid
  })
  const imageGallery4 = data.hero.nodes[0].data.body[3].items.map(image => {
    return image.gallery_image.localFile.childImageSharp.fluid
  })
  //Alt Text for image
  const imageGallery1AltText = data.hero.nodes[0].data.body[0].items.map(
    image => {
      return image.gallery_image.alt
    }
  )
  const imageGallery2AltText = data.hero.nodes[0].data.body[1].items.map(
    image => {
      return image.gallery_image.alt
    }
  )
  const imageGallery3AltText = data.hero.nodes[0].data.body[2].items.map(
    image => {
      return image.gallery_image.alt
    }
  )
  const imageGallery4AltText = data.hero.nodes[0].data.body[3].items.map(
    image => {
      return image.gallery_image.alt
    }
  )

  // Check if hero is in view or not
  const hero = useRef()
  const ref = useRef()
  const onScreen = useOnScreen(ref, "-50px")
  const clipboardConfirmation = useRef()
  // Cta Modal
  const [modal, setModal] = useState(false)
  const showModal = e => {
    if (e.target.classList.contains("modalBtn")) {
      setModal(true)
    }
  }
  const hideModal = e => {
    setModal(false)
  }
  const handlePaste = e => {
    navigator.clipboard.writeText("contact@kubo.co.in")
    setModal(false)
    clipboardConfirmation.current.classList.add("show")
    setTimeout(() => {
      clipboardConfirmation.current.classList.remove("show")
    }, 1500)
    return false
  }

  useEffect(() => {
    const showHero = hero.current
    showHero.style.opacity = 1
  }, [])

  return (
    <>
      <div className="hero" ref={hero} style={{ opacity: 0 }}>
        <div className="heroHeading">
          <h1 className="heroHeading-main heroTitle">
            Your Business needs a blazing fast website.
          </h1>
          <p className="heroHeading-main">{heroSubHeading}</p>
        </div>
        <div className="heroMobileCta mb-24">
          <a className="primaryCta mb-24" href="tel:+91-9693943540">
            Let's Connect
          </a>
          <div className="secondaryCta-wrapper ">
            <Link to="/project" className="secondaryCta secondaryCta-light">
              PROJECTS
            </Link>
          </div>
        </div>

        <div className="heroGallery" ref={ref}>
          <div className="heroGallery-grid--container">
            <div className="gallery1">
              <ImageSlide
                imageGallery={imageGallery1}
                imageAltText={imageGallery1AltText}
                direction={"top"}
                duration={6000}
                inView={onScreen}
              />
            </div>
            <div className="gallery2">
              <ImageSlide
                imageGallery={imageGallery2}
                imageAltText={imageGallery2AltText}
                direction={"left"}
                duration={5000}
                inView={onScreen}
              />
            </div>
            <div className="gallery3">
              <ImageSlide
                imageGallery={imageGallery3}
                imageAltText={imageGallery3AltText}
                direction={"bottom"}
                duration={4500}
                inView={onScreen}
              />
            </div>
            <div className="gallery4">
              <ImageSlide
                imageGallery={imageGallery4}
                imageAltText={imageGallery4AltText}
                direction={"right"}
                duration={3000}
                inView={onScreen}
              />
            </div>
            <div
              className="cta"
              role="button"
              //onMouseEnter={showModal}
              onMouseLeave={hideModal}
              tabIndex="-1"
            >
              <div
                role="button"
                className="primaryCta mb-24 modalBtn"
                //onClick={showModal}
                onMouseEnter={showModal}
                tabIndex="-1"
              >
                Let's Connect
                <div className={`ctaModal ${modal ? "ctaModal-active" : ""}`}>
                  <a href="mailto:contact@kubo.co.in">E-Mail</a>
                  <Link to="/contact">Message Us</Link>
                  <a href="tel:+91-9693943540">Call</a>
                  <button onClick={handlePaste}>Copy Email</button>
                </div>
              </div>
              <div className="secondaryCta-wrapper">
                <Link to="/project" className="secondaryCta secondaryCta-light">
                  PROJECTS
                </Link>
              </div>
            </div>
          </div>
          <p className="copyConfirmation" ref={clipboardConfirmation}>
            Email copied to your clipboard
          </p>
        </div>
      </div>
    </>
  )
}

export default Hero
