import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

const About = () => {
  const data = useStaticQuery(graphql`
    {
      about: allPrismicHomepage {
        nodes {
          data {
            about_paragraph {
              text
              raw
            }
          }
        }
      }
    }
  `)
  const aboutPara = data.about.nodes[0].data.about_paragraph.raw

  return (
    <section className="homepageAbout">
      <div className="homepageAbout-content">
        <div className="homepageAbout-content--heading">
          <h2 className="section-heading text-green">KUBO</h2>
        </div>
        <div className="homepageAbout-content--para">
          <RichText render={aboutPara} />
        </div>
      </div>
      <div className="homepageAbout-services">
        <div className="homepageAbout-services--cards">
          <div className="triangle-overlay one"></div>
          <div className="triangle-overlay two"></div>
          <div className="triangle-overlay three"></div>
          {/* <img className="services-icons" src={Design} alt="" /> */}
          <p>Web Designing</p>
        </div>
        <div className="homepageAbout-services--cards">
          <div className="circle-overlay one"></div>
          <div className="circle-overlay two"></div>
          <div className="circle-overlay three"></div>
          {/* <img className="services-icons" src={Dev} alt="" /> */}
          <p>Web Development</p>
        </div>
        <div className="homepageAbout-services--cards">
          <div className="square-overlay one"></div>
          <div className="square-overlay two"></div>
          <div className="square-overlay three"></div>
          {/* <img className="services-icons" src={Seo} alt="" /> */}
          <p>SEO and Security</p>
        </div>
        <div className="homepageAbout-services--cards">
          <div className="triangle-overlay one"></div>
          <div className="triangle-overlay two"></div>
          <div className="triangle-overlay three"></div>
          {/* <img className="services-icons" src={Graphics} alt="" /> */}
          <p>Digital Graphics</p>
        </div>
      </div>
    </section>
  )
}

export default About
