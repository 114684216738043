import React from "react"
import { Link } from "gatsby"
const BlogCard = props => {
  return (
    <>
      <div className={`blogCard-wrapper`}>
        <div className="blogCard-bar">
          <span className="red"></span>
          <span className="yellow"></span>
          <span className="green"></span>
        </div>
        <div className="blogCard">
          <h3 className="blogCard-title">{props.title}</h3>
          <p>{props.desc}...</p>
          <Link to={`/articles/${props.url}`} className="pageLinks">
            read post
          </Link>
        </div>
      </div>
    </>
  )
}

export default BlogCard
