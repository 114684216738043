import React from "react"
import BlogCard from "./BlogCard"
import { useStaticQuery, graphql, Link } from "gatsby"
const Blog = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicArticles(
        sort: { fields: first_publication_date, order: DESC }
      ) {
        nodes {
          uid
          data {
            article_hero_image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            article_title {
              text
            }
            articles_body {
              text
            }
          }
        }
      }
    }
  `)
  const articles = data.allPrismicArticles.nodes
  return (
    <section className="homepageBlog">
      <div className="homepageBlog-heading mb-48">
        <h2 className="section-heading text-green">
          Latest <br /> Articles
        </h2>

        <Link to="/articles" className="pageLinks">
          all articles
        </Link>
      </div>
      <div className="homepageBlog-content">
        {articles.slice(0, 3).map((article, index) => {
          const shortDesc = article.data.articles_body.text
            .split(" ")
            .slice(0, 25)
            .join(" ")

          return (
            <BlogCard
              key={article.uid}
              title={article.data.article_title.text}
              url={article.uid}
              desc={shortDesc}
              delay={index + 1}
            />
          )
        })}
      </div>
    </section>
  )
}

export default Blog
