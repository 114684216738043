import React from "react"

import { useStaticQuery, graphql, Link } from "gatsby"

import ProCard from "./ProjectCard"
import Text from "../RevealingText"

const Projects = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicProjects(
        sort: { fields: first_publication_date, order: DESC }
      ) {
        nodes {
          uid
          data {
            project_deliveries {
              text
            }
            project_title {
              text
            }

            project_main_image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2500, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.allPrismicProjects.nodes.slice(0, 2)

  return (
    <section className="homepageProjects">
      <div className="homepageProjects-heading mb-48">
        <h2 className="section-heading text-green">
          Our <br /> Projects
        </h2>

        <Link to="/project" className="pageLinks">
          all works
        </Link>
      </div>
      <div className="homepageProjects-content">
        {projects.map(project => {
          return (
            <ProCard
              key={
                project.data.project_main_image.localFile.childImageSharp.fluid
                  .src
              }
              title={project.data.project_title.text}
              image={
                project.data.project_main_image.localFile.childImageSharp.fluid
              }
              deliveries={project.data.project_deliveries.text}
              link={project.uid}
              altText={project.data.project_main_image.alt}
            />
          )
        })}
      </div>
    </section>
  )
}

export default Projects
