import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/homepage/Hero"
import About from "../components/homepage/About"
import Projects from "../components/homepage/Projects"
import Blog from "../components/homepage/Blog"

const Index = () => {
  return (
    <Layout>
      <Seo title={`Kubo Web Agency `} isArticle={false} />
      <Hero />
      <About />

      <Projects />

      <Blog />
    </Layout>
  )
}

export default Index
