import React from "react"

import { Link } from "gatsby"
import Image from "gatsby-image"
import Word from "../RevealingWord"

const ProjectCard = props => {
  return (
    <div className="homepageProject-Card">
      <Link
        exit={{ x: "-200px" }}
        to={`/project/${props.link}`}
        className="homepageProject-Card--image mb-32 "
        title={`view ${props.title}`}
      >
        {/* <Image fluid={ProImage} /> */}
        <Image
          fluid={props.image}
          className="scale-image"
          alt={props.altText}
        />
      </Link>

      <div className="homepageProject-Card--details">
        <h2 className="paraHeading projectTitle">{props.title}</h2>
        <p className="projectDesc text-green mb-16 ">{props.deliveries}</p>
        <Link to={`/project/${props.link}`} className="pageLinks mb-24 ">
          case study
        </Link>
      </div>
    </div>
  )
}

export default ProjectCard
