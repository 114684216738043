import React, { useEffect, useRef } from "react"
import Image from "gatsby-image"
const ImageSlide = props => {
  let slideDirection = props.direction
  let isInView = props.inView

  // const images = [
  //   "https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
  //   "https://images.unsplash.com/photo-1601665472660-45cfad42e6ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
  //   "https://images.unsplash.com/photo-1601643157091-ce5c665179ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60",
  // ]

  const images = props.imageGallery
  const altText = props.imageAltText

  const [count, setCount] = React.useState(0)
  const slideContainer = useRef()
  //Direction
  const leftToRight = `translate3d(-${count}00%,0,0)`
  const rightToLeft = `translate3d(${count}00%,0,0)`
  const topToBottom = `translate3d(0,${count}00%,0)`
  const bottomToTop = `translate3d(0,-${count}00%,0)`

  // Set Direction
  const getDirection = slideDirection => {
    return {
      right: rightToLeft,
      left: leftToRight,
      top: topToBottom,
      bottom: bottomToTop,
    }[slideDirection]
  }

  // Functions to set position of slide container according to direction
  const moveToLeft = () => {
    slideContainer.current.classList.add("moveToLeft")
    slideContainer.current.style.left = "-300%"
    slideContainer.current.style.top = 0
  }
  const moveToBottom = () => {
    slideContainer.current.classList.add("moveToBottom")
    slideContainer.current.style.left = 0
    slideContainer.current.style.top = "-300%"
  }
  const moveToTop = () => {
    slideContainer.current.classList.add("moveToTop")
  }

  useEffect(() => {
    // Set the slide container position according to direction
    if (slideDirection === "right") {
      moveToLeft()
    } else if (slideDirection === "top") {
      moveToBottom()
    } else if (slideDirection === "bottom") {
      moveToTop()
    }

    // Reset the image back to first when slide arrives at last position
    const resetImages = () => {
      slideContainer.current.style.transform = "translate3d(0,0,0)"
      slideContainer.current.style.transition = "none"
    }

    if (count === 3) {
      slideContainer.current.addEventListener("transitionend", resetImages)
    }
    // Set transition for each slide transitions
    slideContainer.current.style.transition =
      "0.8s cubic-bezier(0.37, 0, 0.63, 1)"

    // Increase the count in a setTimeout functions only when slide container is in view
    const timer = setTimeout(() => {
      clearTimeout(timer)
      !isInView || count >= 3 ? setCount(0) : setCount(count + 1)
    }, props.duration)

    // Clean Up function for useEffect
    return () => {
      slideContainer.current.removeEventListener("transitionend", resetImages)
      clearTimeout(timer)
    }
  }, [count, isInView, slideDirection, props.duration])

  return (
    <div className="slideWrapper">
      <div
        ref={slideContainer}
        className="imageSlideContainer"
        style={{ transform: `${getDirection(slideDirection)}` }}
      >
        {images.map((img, index) => {
          return <Image fluid={img} key={img.src} alt={altText[index]} />
        })}
        <Image fluid={images[0]} alt={altText[0]} />
      </div>
    </div>
  )
}

export default ImageSlide
